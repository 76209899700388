import React, { useState } from "react"
import { navigate } from '@reach/router'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Layout from '../../../layouts/Website'
import { LinkIn } from '../../../components/Link'
import Paper, { PaperClean } from '../../../components/Paper'
import { HeadingBase } from '../../../components/Heading'
import FieldPassword from '../../../components/Form/FieldPassword'
import FieldTextControlled from '../../../components/Form/FieldTextControlled'

import { useAuth } from '../../../context'

const initial = {
  email: '',
  password: ''
}

const Login = () => {
  const { login } = useAuth()
  const [credentials, setCredentials] = useState(initial)
  const [error, setError] = useState('')

  async function handleLogin(event) {
    event.preventDefault()
    try {
      await login(credentials.email, credentials.password)
        .then(() => {
          navigate('/console')
        })
    } catch {
      setError('Oops, smth went wrong. Please use the email address you registered with.');
    }
  }
  const setEmail = (value) => {
    setCredentials({...credentials, email: value})
  }
  const setPassword = (value) => {
    setCredentials({...credentials, password: value})
  }
  return (
    <Layout>
      <Grid item xs={12}>
        <Paper>
          <HeadingBase title='Login' />
        </Paper>
      </Grid>
      <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <PaperClean>
                  {error ? (
                    <>
                    <Typography variant='subtitle1' component='h6'
                      paragraph align='left'
                    >
                    {error}
                    </Typography>
                    <Button
                      onClick={() => setError('')}
                    >
                      Try Again
                    </Button>
                    </>
                  ) : (
                    <>
                    <form onSubmit={handleLogin}>
                    <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FieldTextControlled
                        label="Email"
                        name="email" type="email"
                        value={credentials.email} setValue={setEmail}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldPassword 
                        label="Password"
                        name="password"
                        value={credentials.password} setValue={setPassword}
                        required={true}
                      />
                    </Grid>
                    </Grid>
                      <Button
                        type="submit"
                        color='primary'
                        variant='outlined'
                      >
                        Submit
                      </Button>
                    </form>
                    <br /><br />
                    <Typography variant='body1' component='p'>
                    <LinkIn 
                      to='/console/reset'
                    >
                      Forgot password?
                    </LinkIn>
                    </Typography>
                    </>  
                  )}
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaperClean>
                  <Typography variant='subtitle1' component='h5'>
                    WELCOME BACK
                  </Typography>
                </PaperClean>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Login
