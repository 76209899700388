import React, { useState } from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { LinkIn } from "../../../components/Link"
import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'

import { useAuth } from "../../../context"

const Activate = () => {
  const { user, loadProfile } = useAuth();
  const [error, setError] = useState('')

  const handleAgree = () => {
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
        agreement: true
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/profile`, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            loadProfile(user)
          } else {
            setError('Something went wrong.')
          }
        })
        .catch(() => {
          setError('Something went wrong.')
        });
      } catch {
        setError('Something went wrong.')
      }
    }).catch(() => {
      setError('Something went wrong.')
    });
  }

  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Typography
              variant='h4' component='h2'
              paragraph
            >
              Agreement
            </Typography>
            <Typography
              variant='subtitle1' component='p'
              align='left'
            >
              Please review our Terms and Conditions
              <br />
              Do not hesitate to {' '}
              <LinkIn to='/contact'>
              contact
              </LinkIn> {' '}
              us regarding any questions.
              <br />
              To use the Console, you have to agree on our Terms and Conditions.
            </Typography>
            {error ? (
              <>
            <Typography
              variant='h6' component='h2'
              paragraph
            >
              ERROR. {error}
            </Typography>
            <Button
              onClick={() => setError('')}
              color='primary'
            >
              Try again
            </Button>
              </>
            ): (
              <Button
              onClick={() => handleAgree()}
              color='primary'
              variant='outlined'
            >
              Agree
            </Button>
            )}
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Activate