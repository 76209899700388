import React, { useEffect } from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'
import Heading from '../../../components/Heading'

import { useAuth } from '../../../context'

const Authenticated = () => {
  const { user, loadProfile, profile } = useAuth()

  useEffect(() => {
    loadProfile(user)
    const interval = setInterval(() => {
      loadProfile(user)
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  if (profile.is_verified) {
    return (
      <Layout>
          <Grid item xs={12}>
            <Paper>
              <Heading title='Console' />
            </Paper>
          </Grid>
      </Layout>
    )
  } else {
    return (
      <Layout>
          <Grid item xs={12}>
            <Paper>
              <Heading title='Console' />
              <Typography
                variant='body2' component='p'
                paragraph
              >
                Welcome to SKLL Chain.
                <br />
                It might take us up to 48 hours to approve your profile.
              </Typography>
              <Typography 
                variant='body2' component='p'
                paragraph
              >
                You will get notified when ready.
              </Typography>
              {/* <Typography 
                variant='body2' component='p'
                paragraph
              >
                Meanwhile, you can explore the system functions located under the 'Console' menu button. 
              </Typography> */}
            </Paper>
          </Grid>
      </Layout>
    )
  }
}

export default Authenticated