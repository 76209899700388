import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  margin: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));

export default function InputAdornments(props) {
  const classes = useStyles();
  const [show, setShow] = React.useState(false)

  const {
    label, name, required, value, setValue
  } = props
  
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
        <FormControl className={clsx(classes.margin, classes.textField)}>
          <InputLabel
            htmlFor="standard-adornment-password"
          >
            {label}
          </InputLabel>
          <Input
            type={show ? 'text' : 'password'}
            value={value}
            onChange={handleChange}
            name={name}
            required={required && required}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color='primary'
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
  );
}
