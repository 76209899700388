import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '66.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions({data}) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion 
        expanded={expanded === data.created}
        onChange={handleChange(data.created)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={data.created+'-content'}
          id={data.created+'-header'}
        >
          <Typography className={classes.heading}
            color={expanded === data.created ? 'primary' : 'white'}
          >
            {data.display}
          </Typography>
          <Typography 
            className={classes.secondaryHeading}
          >
             {' '+data.created.slice(5,16).replace(/T/g, " @ ")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container >
          {data.fields.map((obj, i) => (
            <Grid item xs={12}
              key={i}
            >
            <Typography variant='body2' color='primary'>
            {obj.name} - {obj.data}
            </Typography>
          </Grid>
          )
          )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}