import React, { useState } from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import Layout from '../../../../layouts/Website'
import Paper, { PaperClean } from '../../../../components/Paper'
import { HeadingBase } from '../../../../components/Heading'

import Profile from './Profile'
import Edit from './Edit'
import Email from './Email'
import Password from './Password'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  }
}));

const IndexPage = () => {
  const classes = useStyles();
  const [ view, setView ] = useState('Profile Settings')

  const viewHandler = (view) => {
    setView(view)
  }

  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <HeadingBase title={view} />
          </Paper>
          <PaperClean>
            <Button onClick={() => viewHandler('Profile Settings')}
                variant={view==='Profile Settings' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Profile
              </Button>
              <Button onClick={() => viewHandler('Edit Profile')}
                variant={view==='Edit Profile' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Edit Profile
              </Button>
              <Button onClick={() => viewHandler('Change Email')}
                variant={view==='Change Email' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Change Email
              </Button>
              <Button onClick={() => viewHandler('Update Password')}
                variant={view==='Update Password' ? 'outlined' : 'text'}
                color='primary'
                className={classes.button}
              >
                Update Password
              </Button>
          </PaperClean>

        </Grid>

        {view === 'Profile Settings' && (
        <Grid item xs={12}>
          <Profile />
        </Grid>
        )}
        {view === 'Edit Profile' && (
        <Grid item xs={12}>
          <Edit viewHandler={viewHandler}/>
        </Grid>
        )}
        {view === 'Change Email' && (
        <Grid item xs={12}>
          <Email viewHandler={viewHandler}/>
        </Grid>
        )}
        {view === 'Update Password' && (
        <Grid item xs={12}>
          <Password viewHandler={viewHandler}/>
        </Grid>
        )}
    </Layout>
  )
}

export default IndexPage
