import React, { useState } from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Layout from '../../../layouts/Website'
import { LinkIn } from '../../../components/Link'
import Paper, { PaperClean } from '../../../components/Paper'
import { HeadingBase } from '../../../components/Heading'
import FieldTextControlled from '../../../components/Form/FieldTextControlled'

import { useAuth } from '../../../context'

const initial = {
  email: '',
}

const Default = () => {
  const { reset } = useAuth()
  const [credentials, setCredentials] = useState(initial)
  const [address, setAddress] = useState('')

  async function handleLogin(event) {
    event.preventDefault()
    try {
      await reset(credentials.email)
        .then(() => {
          setAddress(credentials.email)
        })
    } catch {
      setAddress(credentials.email)
    }
  }
  const setEmail = (value) => {
    setCredentials({...credentials, email: value})
  }
  return (
    <Layout>
      <Grid item xs={12}>
        <Paper>
          <HeadingBase title='Reset Password' />
        </Paper>
      </Grid>
      <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <PaperClean>
                  {address ? (
                      <>
                      <Typography variant='subtitle1' component='p'
                        paragraph align='left'
                      >
                      The password reset instructions have been sent to:
                      <b> {address}</b>
                      </Typography>
                      <Typography variant='subtitle1' component='p'
                        paragraph align='left'
                      >
                      Please check your email address.
                      </Typography>
                      </>
                    ):(
                    <form onSubmit={handleLogin}>
                    <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <FieldTextControlled
                        label="Email"
                        name="email" type="email"
                        value={credentials.email} setValue={setEmail}
                        required={true}
                      />
                    </Grid>
                    </Grid>
                      <Button
                        type="submit"
                        color='primary'
                        variant='outlined'
                      >
                        Submit
                      </Button>
                    </form>
                  )}
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaperClean>
                  <Typography variant='subtitle1' component='h5'
                    paragraph
                  >
                    READ ME
                  </Typography>
                  <Typography variant='body2' component='p'
                    align='justify' paragraph
                  >
                    Please submit the email address you
                    registered with.
                  </Typography>
                  <Typography variant='body2' component='p'
                    align='justify' paragraph
                  >
                    If you did not receive the password reset
                    instructions, you can use the
                    {' '}
                    <LinkIn
                      to='/contact'
                    >
                      contact form
                    </LinkIn>
                    {' '}
                    or other communication methods
                    to get in touch.
                  </Typography>
                </PaperClean>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Default
