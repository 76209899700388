import React from "react"

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { LinkTab } from '../../../../components/Link'
import Paper, { PaperClean } from '../../../../components/Paper'
import { useAuth } from "../../../../context"

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '10px'
  }
}));

const IndexPage = () => {
  const classes = useStyles();
  const { user, profile } = useAuth();

  return (
    <Paper>
      <Grid container>
      <Grid item xs={12} sm={4} xl={4}>
        <PaperClean>
        <Typography variant='h6' component='p'
            align='left' paragraph
          >
            SOCIAL
          </Typography>

          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [WEBSITE] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' paragraph
          >
            <LinkTab
              href={`https://${profile.website}`}
            >
            {profile.website}
            </LinkTab>
          </Typography>

          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [INSTAGRAM] 
          </Typography>
          <Typography variant='body2' component='p'
            align='left' paragraph
          >
            <LinkTab
              href={`https://instagram.com/${profile.instagram}`}
            >
            @{profile.instagram}
            </LinkTab>
          </Typography>

          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [TELEGRAM]
          </Typography>
          <Typography variant='body2' component='p'
            align='left' paragraph
          >
            + {profile.telegram}
          </Typography>

          <Typography variant='body2' component='p'
            align='left'
            className={classes.label}
          >
            [WHATSAPP]  
          </Typography>
          <Typography variant='body2' component='p'
            align='left' paragraph
          >
            + {profile.whatsapp}
          </Typography>
        </PaperClean>
      </Grid>
      <Grid item xs={12} sm={4} xl={4}>
          <PaperClean>
          <Typography variant='h6' component='p'
              align='left' paragraph
            >
              PERSONAL
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [EMAIL] 
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              {user.email}
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [PHONE]
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              + {profile.personal_phone}
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [NAME] 
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              {profile.first_name} {profile.last_name}
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [ADDRESS] 
            </Typography>
            <Typography variant='body2' component='p'
              align='left' 
            >
              {profile.address}
            </Typography>
            <Typography variant='body2' component='p'
              align='left' 
            >
              {profile.postal_code} {profile.city}
            </Typography>
            <Typography variant='body2' component='p'
              align='left' 
            >
              {profile.country}
            </Typography>
          </PaperClean>
        </Grid>
        <Grid item xs={12} sm={4} xl={4}>
          <PaperClean>
            <Typography variant='h6' component='p'
              align='left' paragraph
            >
              BUSINESS
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [EMAIL]
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              {profile.business_email}
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [USERNAME]
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              {profile.username}
            </Typography>

            <Typography variant='body2' component='p'
              align='left'
              className={classes.label}
            >
              [PHONE]
            </Typography>
            <Typography variant='body2' component='p'
              align='left' paragraph
            >
              + {profile.business_phone}
            </Typography>
          </PaperClean>
        </Grid>
      </Grid>
    </Paper>

  )
}

export default IndexPage
