import React, { useState } from "react"

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import FieldTextControlled from '../../../components/Form/FieldTextControlled'
import FieldMultiline from '../../../components/Form/FieldMultiline'
import { useAuth } from '../../../context'

const initial = {
  username: '',
  first_name: '',
  last_name: '',
  business_email: '',
  personal_phone: '',
  business_phone: '',
  address: '',
  postal_code: '',
  country: '',
  city: '',
  website: '',
  instagram: '',
  telegram: '',
  whatsapp: '',
  message: ''
}

const IndexPage = () => {
  const { user, loadProfile } = useAuth()
  
  const [error, setError] = useState('')
  const [payload, setPayload] = useState(initial)

  const submit = e => {
    e.preventDefault()
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
        ...payload
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/profile`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: { 'Content-Type': 'application/json' },
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            loadProfile(user)
          } else if (data.status === 3) {
            setError('Username already exists.')
          } else {
            setError('Something went wrong.')
          }
        })
        .catch(() => {
          setError('Something went wrong.')
        });
      } catch {
        setError('Something went wrong.')
      }
    }).catch(() => {
      setError('Something went wrong.')
    });
  }

  const setUsername = (value) => {
    setPayload({...payload, username: value})
  }
  const setBusinessEmail = (value) => {
    setPayload({...payload, business_email: value})
  }
  const setFirstName = (value) => {
    setPayload({...payload, first_name: value})
  }
  const setLastName = (value) => {
    setPayload({...payload, last_name: value})
  }
  const setPersonalPhone = (value) => {
    !isNaN(value) &&
      setPayload({...payload, personal_phone: value})
  }
  const setBusinessPhone = (value) => {
    !isNaN(value) &&
      setPayload({...payload, business_phone: value})
  }
  const setAddress = (value) => {
    setPayload({...payload, address: value})
  }
  const setPostalCode = (value) => {
    setPayload({...payload, postal_code: value})
  }
  const setCountry = (value) => {
    setPayload({...payload, country: value})
  }
  const setCity = (value) => {
    setPayload({...payload, city: value})
  }
  const setWebsite = (value) => {
    setPayload({...payload, website: value})
  }
  const setInstagram = (value) => {
    setPayload({...payload, instagram: value})
  }
  const setTelegram = (value) => {
    setPayload({...payload, telegram: value})
  }
  const setWhatsApp = (value) => {
    setPayload({...payload, whatsapp: value})
  }
  const setMessage = (value) => {
    setPayload({...payload, message: value})
  }

  return (
    <>
      { error && (
      <Typography variant='h6' component='p'
        paragraph align='left'
      >
        ERROR. {error}
      </Typography>
      )}
      <form onSubmit={submit}>
        <Grid container spacing={1}>
          
        <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Username"
            name="username" type="text"
            value={payload.username} setValue={setUsername}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Business Email"
            name="business_email" type="text"
            value={payload.business_email} setValue={setBusinessEmail}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="First Name"
            name="first_name" type="text"
            value={payload.first_name} setValue={setFirstName}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Last Name"
            name="last_name" type="text"
            value={payload.last_name} setValue={setLastName}
            required={true}
          />
          </Grid>
          <Grid item xs={12}>
          <FieldTextControlled
            variant='outlined'
            label="Personal Phone Number"
            name="personal_phone" type="text"
            value={payload.personal_phone} setValue={setPersonalPhone}
            required={true}
          />
          </Grid>
          <Grid item xs={12}>
          <FieldTextControlled
            variant='outlined'
            label="Business Phone Number"
            name="business_phone" type="text"
            value={payload.business_phone} setValue={setBusinessPhone}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Address"
            name="address" type="text"
            value={payload.address} setValue={setAddress}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Postal Code"
            name="postal_code" type="text"
            value={payload.postal_code} setValue={setPostalCode}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Country"
            name="country" type="text"
            value={payload.country} setValue={setCountry}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="City"
            name="city" type="text"
            value={payload.city} setValue={setCity}
            required={true}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Website"
            name="website" type="text"
            value={payload.website} setValue={setWebsite}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Instagram"
            name="instagram" type="text"
            value={payload.instagram} setValue={setInstagram}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="Telegram"
            name="telegram" type="text"
            value={payload.telegram} setValue={setTelegram}
          />
          </Grid>
          <Grid item xs={12} md={6}>
          <FieldTextControlled
            variant='outlined'
            label="WhatsApp"
            name="whatsapp" type="text"
            value={payload.whatsapp} setValue={setWhatsApp}
          />
          </Grid>
        </Grid>
        <Grid item xs={12}>
            <FieldMultiline
              label="Notes / Reference"
              name="message" 
              value={payload.message} setValue={setMessage}
              required={true}
            />
          </Grid>
      <Button
        type="submit"
        color='primary'
        variant='outlined'
      >
        Create Profile
      </Button>
    </form>
    </>
  )
}

export default IndexPage
