import React from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'

const Blocked = () => {
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Typography
              variant='h4' component='h2'
              paragraph
            >
              Console Blocked
            </Typography>
            <Typography
              variant='subtitle1' component='h2'
              align='left'
            >
              Something went wrong.
              <br />
              Please contact us.
            </Typography>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Blocked