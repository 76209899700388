import React from "react"
import { Router } from "@reach/router"

import { useAuth } from "../../context"

import { 
  Anonymous,
  Register,
  Login,
  Reset,

  Authenticated,
  Settings,
  
  Verify,
  Setup,
  Activate,
  Approve,
  Blocked,
  Loading
} from '../../views/console'


const ConsoleAnonymous = () => {
  return (
    <>
    <Router>
    <Anonymous default="/console"/> 
    <Login path="/console/login"/>
    <Register path="/console/register"/>
    <Reset path="/console/reset"/>
    </Router>
    </>
  )
}

const ConsoleVerify = () => {
  return (
    <>
    <Router>
    <Verify default="/console" />
    </Router>
    </>
  )
}

const ConsoleSetup = () => {
  return (
    <>
    <Router>
    <Setup default="/console"/>
    </Router>
    </>
  )
}

const ConsoleActivate = () => {
  return (
    <>
    <Router>
    <Activate default="/console" />
    </Router>
    </>
  )
}

const ConsoleApprove = () => {
  return (
    <>
    <Router>
    <Approve default="/console" />
    </Router>
    </>
  )
}

const ConsoleBlocked = () => {
  return (
    <>
    <Router>
    <Blocked default="/console"/>
    </Router>
    </>
  )
}

const ConsoleLoading = () => {
  return (
    <>
    <Router>
    <Loading default="/console"/>
    </Router>
    </>
  )
}

const ConsoleAuthenticated = () => {
  return (
    <>
    <Router>
    <Authenticated default="/console"/>
    <Settings path="/console/settings"/>
    </Router>
    </>
  )
}

const Page = () => {
  const { user, profile } = useAuth()
  if (user===null) {
    return <ConsoleAnonymous />
  }
  if (!user.emailVerified) {
    return <ConsoleVerify />
  }
  if (profile===null) {
    return <ConsoleLoading />
  }
  if (!profile.is_setup) {
    return <ConsoleSetup />
  }
  if (!profile.is_active) {
    return <ConsoleActivate /> 
  }
  if (!profile.is_approved) {
    return <ConsoleApprove />
  }
  if (profile.is_blocked) {
    return <ConsoleBlocked />
  }
  return <ConsoleAuthenticated />
  // return (
  //   user ?
  //     !loading ?
  //       user.emailVerified ?
  //         profile ?
  //           profile.is_setup ?
  //             profile.is_active ?
  //               profile.is_blocked ?
  //                 <>Blocked</>
  //               :
  //                 <>Authenticated</>
  //             :
  //               <Activate</> 
  //           :
  //             <>Setup</>
  //         :
  //           <>Loading</>
  //       :
  //         <>Verify</>
  //     :
  //       <>Loading</>
  //   :
  //     <>Anonymous</>
  // )
}

export default Page
