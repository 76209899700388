import React, { useEffect } from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'
import Heading from '../../../components/Heading'

import { useAuth } from '../../../context'

const Verify = () => {
  const { reloadUser } = useAuth()
  useEffect(() => {
    const interval = setInterval(() => {
      reloadUser()
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Heading title='Email Confirmation' />
            <Typography
              variant='body2' component='p'
            >
              A verification email has been sent, check your email.
            </Typography>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Verify