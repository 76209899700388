import React from "react"

import Grid from '@material-ui/core/Grid'

import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'
import Heading from '../../../components/Heading'

import Console from '../../../apps/Console'

const Authenticated = () => {

  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Heading title='Console' />
            <Console />
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Authenticated