import React from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'
import Heading from '../../../components/Heading'

import { LinkIn } from '../../../components/Link'

const Anonymous = () => {
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <Heading title='Console' />

            <Typography 
              variant='body1' component='p'
            >
              Please {' '}
              <LinkIn to='/console/login'>
                login
              </LinkIn> {' '}
              to proceed.
            </Typography>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Anonymous