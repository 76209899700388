import React, { useState } from "react"
import { navigate } from '@reach/router'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Paper, { PaperClean } from '../../../../components/Paper'
import FieldTextControlled from '../../../../components/Form/FieldTextControlled'
import FieldPassword from '../../../../components/Form/FieldPassword'

import { useAuth } from '../../../../context'

const initial = {
  email: '',
  password: ''
}

const UpdateEmail = () => {
  const { user, updateEmail, login } = useAuth()
  const [credentials, setCredentials] = useState(initial)
  const [error, setError] = useState('')

  async function handleUpdate(event) {
    event.preventDefault()
    try {
      await login(user.email, credentials.password)
      await updateEmail(credentials.email)
      await login(credentials.email, credentials.password)
        .then((userCredentials) => {
          var user = userCredentials.user;
          user.sendEmailVerification()
        })
        navigate('/console')
    } catch {
      setError('Oops, smth went wrong.');
    }
  }
  const setEmail = (value) => {
    setCredentials({...credentials, email: value})
  }
  const setPassword = (value) => {
    setCredentials({...credentials, password: value})
  }

  return (
    <Paper>
      <Grid container>
      <Grid item xs={12} sm={6}>
        <PaperClean>
            {error ? (
              <>
              <Typography variant='subtitle1' component='h6'
                paragraph align='left'
              >
              {error}
              </Typography>
              <Button
                onClick={() => setError('')}
              >
                Try Again
              </Button>
              </>
            ) : (
              <form onSubmit={handleUpdate}>
              <Grid container spacing={1}>
              <Grid item xs={12}>
                <FieldTextControlled
                  label="New Email"
                  name="email" type="email"
                  value={credentials.email} setValue={setEmail}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldPassword 
                  label="Password"
                  name="password"
                  value={credentials.password} setValue={setPassword}
                  required={true}
                />
              </Grid>
              </Grid>
                <Button
                  type="submit"
                  color='primary'
                  variant='outlined'
                >
                  Submit
                </Button>
              </form>
            )}
        </PaperClean>
        </Grid>

        <Grid item xs={12} sm={6}>
         <PaperClean>
          <Typography variant='body2' component='h5'
            align='left' paragraph
          >
            Submit your NEW Email address, then check your NEW inbox for confirmation letter.
          </Typography>
          <Typography variant='body2' component='h5'
            align='left' paragraph
          >
            You will receive a reset link on the OLD email address.
          </Typography>
          <Typography variant='body2' component='h5'
            align='left' paragraph
          >
            Your account will be locked until you confirm your NEW email address, or reset this change by following the link received on the OLD email address.
          </Typography>

          <Typography variant='body2' component='h5'
            align='left' paragraph
          >
            ATTENTION: If you did not change your email address, but received a reset letter, please change your password and contact us asap. 
          </Typography>
          </PaperClean>
        </Grid>

      </Grid>
    </Paper>

  )
}

export default UpdateEmail
