import React, { useState } from "react"
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import Paper, { PaperClean } from '../../../../components/Paper'
import FieldPassword from '../../../../components/Form/FieldPassword'

import { useAuth } from '../../../../context'

const initial = {
  passwordCurrent: '',
  password: ''
}

const Default = () => {
  const { user, login, updatePassword } = useAuth()
  const [credentials, setCredentials] = useState(initial)
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')

  async function handleUpdate(event) {
    event.preventDefault()
    try {
      await login(user.email, credentials.passwordCurrent)
      await updatePassword(credentials.password)
      setMessage('Your password was successfully updated.')
    } catch {
      setError('Oops, smth went wrong.');
    }
  }

  const setPasswordCurrent = (value) => {
    setCredentials({...credentials, passwordCurrent: value})
  }
  const setPassword = (value) => {
    setCredentials({...credentials, password: value})
  }

  return (
    <Paper>
      <Grid container>
      <Grid item xs={12} sm={6}>
        <PaperClean>

            {error ? (
              <>
              <Typography variant='subtitle1' component='h6'
                paragraph align='left'
              >
              {error}
              </Typography>
              <Button
                onClick={() => setError('')}
              >
                Try Again
              </Button>
              </>
            ) : (
              message ? (
              <Typography variant='subtitle1' component='h6'
                paragraph align='left'
              >
              {message}
              </Typography>
              ) : (
                <form onSubmit={handleUpdate}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                 <FieldPassword 
                    label="Current Password"
                    name="passwordCurrent"
                    value={credentials.passwordCurrent} setValue={setPasswordCurrent}
                    required={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FieldPassword 
                    label="Password"
                    name="password"
                    value={credentials.password} setValue={setPassword}
                    required={true}
                  />
                </Grid>
                </Grid>
                <Button
                    type="submit"
                    color='primary'
                    variant='outlined'
                  >
                    Submit
                  </Button>
                </form>
              )
            )}
        </PaperClean>
        </Grid>

        <Grid item xs={12} sm={6}>
         <PaperClean>
            <Typography variant='body2' component='h5'>
              Please never use trivial passwords.
            </Typography>
            </PaperClean>
        </Grid>

      </Grid>
    </Paper>

  )
}

export default Default
