import React from "react"

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Layout from '../../../layouts/Website'
import Paper, { PaperClean } from '../../../components/Paper'
import { HeadingBase } from '../../../components/Heading'
import Form from './SetupForm'

const Default = () => {
    return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
            <HeadingBase title='Profile Setup' />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <PaperClean>
                <Form />
                </PaperClean>
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaperClean>
                  <Typography variant='body2' component='p'
                  paragraph
                >
                  READ ME
                </Typography>
                <Typography variant='body2' component='p'
                  paragraph align='left'
                >
                  Welcome to SKLL Chain.
                </Typography>
                <Typography variant='body2' component='p'
                  paragraph align='left'
                >
                  Let's get started by setting up your personal profile.
                </Typography>
                <Typography variant='body2' component='p'
                  paragraph align='left'
                >
                  Your profile can be related to multiple projects or organisations, which you will be able to setup later.
                </Typography>
                <Typography variant='body2' component='p'
                  paragraph align='left'
                >
                  Here is a brief explanation of the basic requirements you have to fill in:
                  <br/>
                  <ul>
                    <li>
                      [username] unique short name, used as your display name.
                    </li>
                    <li>
                      [business email] used for communication purposes; visible to your project's members.
                    </li>
                    <li>
                      [first/last name] used for invoicing; visible to your project's team members.
                    </li>
                    <li>
                      [personal phone] used for critical notifications and invoicing; not visible to other users.
                    </li>
                    <li>
                      [business phone] visible to your project's team members.
                    </li>
                    <li>
                      [address] used for invoicing; please provide your BILLING address; not visible to other users.
                    </li>
                    <li>
                      [website / social] used for communication purposes; visible to your project's members.
                    </li>
                    <li>
                      [notes] person or organisation who suggested you to register; if you do not have a reference, please briefly describe your intent of using this platform; you will not be able to see neither other users nor organisations of this platform.
                    </li>
                  </ul>
                </Typography>
                </PaperClean>
              </Grid>

            </Grid>
          </Paper>
        </Grid>
    </Layout>
  )
}

export default Default