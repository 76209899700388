import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import FeatureDialog from './FeatureDialog'

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
    textAlign: 'left'
  },
});

export default function Default({data}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <FeatureDialog
        data={data}
        handleClose={handleClose}
        open={open}
      />

      <Title>{data.name}</Title>
      <Typography component="p" variant="h4">

      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
      Total: {data.total}
      <br />
      Unique: {data.unique}
      <br />
      Today: {data.today}
      </Typography>
      <div>
        <Button color="primary" onClick={handleClickOpen}>
          Expand
        </Button>
      </div>
    </React.Fragment>
  );
}