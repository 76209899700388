import React from "react"

import Grid from '@material-ui/core/Grid'
import Layout from '../../../layouts/Website'
import Paper from '../../../components/Paper'
import Heading from '../../../components/Heading'

const Loading = () => {
  return (
    <Layout>
        <Grid item xs={12}>
          <Paper>
          <Heading title='... loading ...' />
          </Paper>
        </Grid>
      </Layout>
  )
}

export default Loading