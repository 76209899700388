import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Feature from './Feature';
import { useAuth } from '../../context'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    // height: 240,
  },
}));

// const data = {
//   functions: [
//     {
//       name: 'Video Plays',
//       total: '213',
//       unique: '78',
//       today: '13',
//       data: [{},],
//     },
//     {
//       name: 'Subscribers',
//       total: '32',
//       unique: '30',
//       today: '3',
//     }
//   ]
// }

export default function Dashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const { user } = useAuth()
  const [ data, setData ] = useState({})

  const loadData = () => {
    user.getIdToken(/* forceRefresh */ true).then((idToken) => {
      let data = {
        token: idToken,
      }
      try {
        fetch(`${process.env.GATSBY_API_URL}/skllchain/console`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        .then(response => response.json())
        .then(data => {
          if (data.status === 0) {
            setData(data.payload)
          } else {
            console.log('Something went wrong.')
          }
        })
        .catch(() => {
          console.log('Something went wrong.')
        });
      } catch {
        console.log('Something went wrong.');
      }
    }).catch(() => {
      console.log('Something went wrong.')
    });
  }

  useEffect(() => {
    user && loadData()
    const interval = setInterval(() => {
      loadData()
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
      <Grid container spacing={3}>
      {data && data.functions && data.functions.map((obj, i) => (
          <Grid key={i} item xs={12} sm={4} md={4} lg={3}>
          <Paper className={fixedHeightPaper}>
            <Feature data={obj}/>
          </Paper>
        </Grid>
        )
      )}


      </Grid>
  );
}